import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
const SEO = ({ title,productsMeta, description, image, pathname }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          productsMeta,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        productsMeta: productsMeta || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
      }
      return (
        <>
          <Helmet title={titleTemplate} titleTemplate={`%s | ${seo.title}`}>
            <html lang="en" />
            <meta name="description" content={seo.description} />
            <meta name="productsMeta" content={seo.productsMeta} />
            <meta name="service-provider" content="The best IT service provides in uganda."></meta>
            <meta name="services" content="Managed IT services, Project management, API financial integration, MQPay, Mobile money integration, Payments Aggregators,IT consultancy, Software development, IT Audit and Security"></meta>
            <meta name="products" content={seo.image} />
            <meta name="image" content=" MQPAY financial integration, ERP, Rentpay , Prodiga digitisation, ERP, GDHEalth system, Uganda, kampala," />
            <meta name="random-searches" content="gdexperts, IT security, uganda, innovation, Kampala Information, bobiwine, statehouse uganda, Covid-19, Biden, Kamala Haris, 4ir In uganda, Telecom software development, Agile software development, ITIL, Payment, Mobile money integration, payments integration, go paperless, Cashless economy, IT companies in uganda, spftware development jobs, IT Jobs " />
            <meta property="og:type" content="" />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`

